import { Reducer } from 'redux';
import { ActionType, ManualServingActions } from './actions';
import { SelectedStatus } from 'types';

export interface ManualServingState {
  selectedStatus: SelectedStatus
}

export const initialState: ManualServingState = {
  selectedStatus: 'requested'
}

const manualServingReducer: Reducer<ManualServingState, ManualServingActions>  = (
    state = initialState,
    action
) => {
    if (action.type === ActionType.UPDATE_SELECTED_STATUS) {
        return {
          ...state,
          selectedStatus: action.payload.selectedStatus
        }
    }
    return state
};

export default manualServingReducer;

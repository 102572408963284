import React from 'react'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { AppState, SelectedStatus } from 'types'
import { updateSelectedStatus } from 'core/actions'

interface Props {
  status: SelectedStatus
  label: string
}

const FilterButton = ({ status, label }: Props): JSX.Element => {
  const classes = useStyles()
  const selectedStatus = useSelector(
    (state: AppState) => state.manualServing.selectedStatus,
  )
  const dispatch = useDispatch()
  return (
    <Button
      variant="contained"
      component={Link}
      className={`${classes.button} ${
        selectedStatus === status ? classes.active : ''
      }`}
      to={{
        pathname: '/manual-servings',
        search: `filter=${JSON.stringify({ status })}`,
      }}
      onClick={() => dispatch(updateSelectedStatus(status))}>
      {label}
    </Button>
  )
}

const useStyles = makeStyles(({ palette }) => ({
  button: {
    width: 173,
    height: 53,
    marginRight: 16,
    fontSize: 18,
    fontWeight: 700,
    borderRadius: 60,
    backgroundColor: palette.common.white,
  },
  active: {
    color: palette.common.white,
    backgroundColor: palette.primary.main,
  },
}))

export default FilterButton

// in src/MyLayout.js
import * as React from 'react'
import { Layout, LayoutProps } from 'react-admin'
import AppBar from './AppBar'
import SideBar from './SideBar'
import Menu from './Menu'

const MyLayout = (props: LayoutProps): JSX.Element => (
  <Layout {...props} appBar={AppBar} sidebar={SideBar} menu={Menu} />
)

export default MyLayout

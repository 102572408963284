import React from 'react'
import { Logout as LogoutBtn } from 'react-admin'
import { LogOut } from 'react-feather'

//  refを渡さないとエラーになるのでReact.forwardRefで作成
const LogoutButton = React.forwardRef(function LogoutButton(
  props: any,
  ref: any,
) {
  return <LogoutBtn {...props} icon={<LogOut />} ref={ref} />
})

export default LogoutButton

import { AuthProvider } from 'ra-core';
import { useAuth0 } from '@auth0/auth0-react';

export const useAuthProvider = (): AuthProvider => {
    const {
        isLoading,
        isAuthenticated,
        user,
        logout,
      } = useAuth0();
    return ({
        async login() {
            return
        },
        async logout() {
            console.log("logout")
            if (isAuthenticated) {
                logout()
            }
        },
        async checkAuth() {
            if (!isAuthenticated) {
                throw Error()
            }
        },
        async getIdentity() {
            console.log("getIdentity")
            if (isLoading || !isAuthenticated) {
                console.log(1)
                throw Error()
            }
            const id = user?.email ?? "unknown"
            return {
                id,
            }
        },
        async getPermissions() {
            if (isLoading || !isAuthenticated) {
                console.log(2)
                throw Error()
            }
            return {}
        },
        async checkError() {
            /*
            const status = error.status;
            if (status === 401 || status === 403) {
              deleteAuthData();
              throw new Error('checkError failed');
            }
            */
        },
    })
}

/*
このDataProviderは、ra-data-json-serverを元に、作成しています
*/
import {stringify} from 'query-string';
import {fetchUtils,DataProvider, HttpError} from 'ra-core';
import { useAuth0 } from '@auth0/auth0-react';

/*
react-adminは、ListのAPIの結果件数を返さないといけない仕様であるが、
不要な仕様による工数の削減の削減のため、ダミーのカウントを設定しています
*/
const dummyTotalCount = Number.MAX_SAFE_INTEGER;

/*
ReferenceInputなどがgetListを呼び出す際に、
デフォルトの挙動では、_startと_endをクエリに含めないため、
デフォルト値を設定しています
*/
const defaultEnd = 1000;

/*
ra-data-json-serverでは、リソースに対するRESTな単純なCRUDしかサポートされていないため、
カスタムメソッドによる更新をサポートするために、拡張を加えています
*/
const parseOriginalResource = (apiUrl: string) => {
    const [resource, customMethod] = apiUrl.split('|');
    return {
      customMethod,
      resource,
    };
  };

export const useDataProvider = (apiUrl: string): DataProvider => {

    const {getIdTokenClaims} = useAuth0()

    const httpClient = async (url: any, options?: fetchUtils.Options) => {
        const headers = new Headers(options?.headers);
        const idToken = await getIdTokenClaims();
        if (idToken && idToken.__raw) {
          headers.set('Authorization', `Bearer ${idToken.__raw}`);
          headers.set('Content-Type', 'application/json');
        }
        return fetchUtils.fetchJson(url, {...options, headers});
      };

    return {
        create: async (originalResource, params) => {
          const {customMethod, resource} = parseOriginalResource(originalResource);
          if (customMethod) {
            throw new Error('can not create with custom method');
          }

          const res = await httpClient(`${apiUrl}/${resource}`, {
            body: JSON.stringify(params.data),
            method: 'POST',
          });
          return {
            data: res.json,
          };
        },

        delete: async (originalResource, params) => {
          const {resource} = parseOriginalResource(originalResource);

          const res = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
          });
          return {
            data: res.json,
          };
        },

        /*
          DELETE MANYは、今のところサポートしていません
        */
        deleteMany: async () => {
          throw new Error('pool admin not support delete many');
        },
        getList: async (originalResource, params) => {
          try {
            const {customMethod, resource} = parseOriginalResource(originalResource);

            if (customMethod) {
              throw new Error('can not getList with custom method');
            }

            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;
            const query = {
              ...fetchUtils.flattenObject(params.filter),
              _end: page * perPage ?? defaultEnd,
              _order: order,
              _sort: field,
              _start: (page - 1) * perPage ?? 0,
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            const res = await httpClient(url);
            return {
              data: res.json,
              total: dummyTotalCount,
            };
          } catch (e: any) {
            throw new HttpError(e.message, e.statusCode, e);
          }
        },

        getMany: (originalResource, params) => {
          const {resource} = parseOriginalResource(originalResource);
          return Promise.all(params.ids).then((id) => {
            const query = {
              id,
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            return httpClient(url).then(({json}) => ({data: json}));
          });
        },

        getManyReference: async (originalResource, params) => {
          const {customMethod, resource} = parseOriginalResource(originalResource);

          if (customMethod) {
            throw new Error('can not getList with custom method');
          }

          const {page, perPage} = params.pagination;
          const {field, order} = params.sort;
          const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _end: page * perPage,
            _order: order,
            _sort: field,
            _start: (page - 1) * perPage,
          };
          const url = `${apiUrl}/${resource}?${stringify(query)}`;

          const res = await httpClient(url);
          return {
            data: res.json,
            total: dummyTotalCount,
          };
        },

        getOne: async (originalResource, params) => {
          const {resource} = parseOriginalResource(originalResource);

          const res = await httpClient(`${apiUrl}/${resource}/${params.id}`);
          return {
            data: res.json,
          };
        },

        update: async (originalResource, params) => {
          try {
            const {customMethod, resource} = parseOriginalResource(originalResource);
            const res = await httpClient(
              `${apiUrl}/${resource}/${params.id}${
                customMethod ? ':' + customMethod : ''
              }`,
              {
                body: JSON.stringify(params.data),
                method: customMethod ? 'POST' : 'PUT',
              }
            );
            if (res.status < 200 || res.status >= 300) {
              const error = await res.json();
              throw error;
            }
            return {
              data: res.json,
            };
          } catch (e: any) {
            throw new HttpError(e.message, e.statusCode, e);
          }
        },

        updateMany: (originalResource, params) => {
          const {customMethod, resource} = parseOriginalResource(originalResource);

          return Promise.all(
            params.ids.map((id) =>
              httpClient(
                `${apiUrl}/${resource}/${id}${
                  customMethod ? ':' + customMethod : ''
                }}`,
                {
                  body: JSON.stringify(params.data),
                  method: customMethod ? 'POST' : 'PUT',
                }
              )
            )
          ).then((responses) => ({data: responses.map(({json}) => json.id)}));
        },
    }
}
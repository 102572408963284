import React from 'react'
import { Login } from 'react-admin'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { useAuth0 } from '@auth0/auth0-react'
import CircularProgress from '@material-ui/core/CircularProgress'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoginForm = (props: any) => {
  const { isLoading, loginWithRedirect } = useAuth0()
  return (
    <CardActions>
      <Button
        variant="contained"
        type="submit"
        color="primary"
        onClick={() => loginWithRedirect()}
        disabled={isLoading}>
        {isLoading && <CircularProgress size={18} thickness={2} />}
        Login
      </Button>
    </CardActions>
  )
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const LoginPage = (props: any): JSX.Element => (
  <Login {...props}>
    <LoginForm />
  </Login>
)

// import { Action } from 'redux'
import { SelectedStatus } from 'types';

export enum ActionType {
  UPDATE_SELECTED_STATUS = 'UPDATE_SELECTED_STATUS'
}

export const updateSelectedStatus = (selectedStatus: SelectedStatus) => ({
    type: ActionType.UPDATE_SELECTED_STATUS,
    payload: { selectedStatus },
}) as const;

export type ManualServingActions = ReturnType<typeof updateSelectedStatus>

import React from 'react'
import { Admin, Resource } from 'react-admin'
import { useAuthProvider } from './authProvider'
import { useDataProvider } from './dataProvider'
import { LoginPage } from './login/Login'
import './App.css'
import { theme } from './layout/Theme'
import Layout from './layout/Layout'
import LogoutButton from './layout/LogoutButton'
import ManualServingList from './domain/manual-servings/ManualServingList'
// import LockerList from './domain/lockers/LockerList'
import stations from './domain/stations'
import manualServingReducer from './core/manualServingReducer'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import japaneseMessages from '@bicstone/ra-language-japanese'

const App = (): JSX.Element => {
  // データを取得するクライアントとエンドポイントのベースURLの初期化
  const authProvider = useAuthProvider()
  const dataProvider = useDataProvider(process.env.REACT_APP_API_ENDPOINT || '')
  const i18nProvider = polyglotI18nProvider(() => japaneseMessages, 'ja', {
    allowMissing: true,
  })
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={LoginPage}
      theme={theme}
      customReducers={{ manualServing: manualServingReducer }}
      layout={Layout}
      logoutButton={LogoutButton}>
      <Resource name="manual-servings" list={ManualServingList} />
      {/* <Resource name="lockers" list={LockerList} /> */}
      <Resource name="stations" {...stations} />
    </Admin>
  )
}

export default App

import { grey } from '@material-ui/core/colors'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    menuBackground: string
    hoverBackground: string
  }
  interface PaletteOptions {
    menuBackground: string
    hoverBackground: string
  }
}

export const theme = {
  palette: {
    primary: {
      main: "#00A9E0",
      light: "#E7F9FF",
      contrastText: '#F4F4F4'
    },
    secondary: {
      main: '#C80000',
    },
    common: {
      white: '#fff',
    },
    divider: grey[300],
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
    background:  {
      default: '#fff',
    },
    menuBackground: '#E5E5E5',
    hoverBackground: '#D5D5D5',
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['Noto Sans JP', 'sans-serif', 'Inter'].join(','),
    // element default font size is 16p
  },
  overrides: {
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: '#D5D5D5',
        },
      }
    },
  }
  // overrides: { RaMenuItemLink } は型エラー。overridesのpropsにRaMenuItemLinkが存在しないため(react routerのコンポーネントで materiau-uiのそれではないため。)
  // createThemeを使わなくても、同じMateriul-uiのthemeOptionsを使っているので結果は変わらない
  // Navlinkとマージして使っているMuiMenuItemのselectedを指定しても効かなかった。
  // interface Overridesでオプションを増やそうとしてもうまくいかず
  // react-adminはtsを使っていて themeOptionsも同じだがうまくいく。原因は不明。
  // このスレッドでも解決せず: https://stackoverflow.com/questions/65120760/change-the-link-colors-in-material-ui-sidebar-drawer-in-a-reactjs-app
}
import * as React from 'react'
import { Menu, MenuItemLink, MenuProps } from 'react-admin'
import { Rss, Coffee } from 'react-feather'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const Path = {
  MANUAL_SEVINGS: '/manual-servings',
  LOCKERS: '/lockers',
  STATIONS: '/stations',
}

const MyMenu = (props: MenuProps): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()
  const iconColor = (path: string) => {
    return window.location.href.includes(path)
      ? theme.palette.primary.main
      : '#BDBDBD'
  }
  return (
    <Menu {...props}>
      <MenuItemLink
        to={Path.MANUAL_SEVINGS}
        primaryText="調理タスク"
        leftIcon={<Rss color={iconColor(Path.MANUAL_SEVINGS)} />}
        className={classes.menuItem}
        activeClassName={classes.active}
      />
      {/* <MenuItemLink
        to={Path.LOCKERS}
        primaryText="ロッカー"
        leftIcon={<Grid color={iconColor(Path.LOCKERS)} />}
        className={classes.menuItem}
        activeClassName={classes.active}
      /> */}
      <MenuItemLink
        to={Path.STATIONS}
        primaryText="ステーション管理"
        leftIcon={<Coffee color={iconColor(Path.STATIONS)} />}
        className={classes.menuItem}
        activeClassName={classes.active}
      />
    </Menu>
  )
}

const useStyles = makeStyles(({ palette }) => ({
  menuItem: {
    height: 42,
    borderLeft: `solid #fff`,
    borderLeftWidth: 8,
    '&:hover': {
      borderRadius: '0px 24px 24px 0px',
      borderLeft: `solid ${palette.hoverBackground}`,
      borderLeftWidth: 8,
    },
  },
  active: {
    backgroundColor: palette.primary.light,
    borderRadius: '0px 24px 24px 0px',
    color: palette.primary.main,
    borderLeft: `solid ${palette.primary.main}`,
    borderLeftWidth: 8,
  },
}))

export default MyMenu

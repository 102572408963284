import * as React from 'react'
import { Sidebar } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

// eslint-disable-next-line
const MySidebar = (props: any): JSX.Element => {
  // anyをSidebarPropsにするとコンパイルエラーになる
  const classes = useStyles()
  return <Sidebar classes={classes} {...props} />
}

const useStyles = makeStyles({
  drawerPaper: {
    paddingTop: 5,
  },
})

export default MySidebar

import React, { useEffect } from 'react'
import {
  ListBase,
  ListProps,
  useListContext,
  Identifier,
  useUpdate,
  useRefresh,
  useNotify,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { Card, Box, Typography, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FilterButton from './FilterButton'
import { AppState } from 'types'
// import updateData from 'mock/updateManualServing.json'

interface LineItem {
  id: number
  imageURL: string
  name: string
}

interface Option {
  label: string
}

const ManualServingCard = (): JSX.Element => {
  useEffect(() => {
    const timer = setInterval(() => {
      // TODO: GetかRefreshの処理を入れる
    }, 30000)
    return () => clearInterval(timer)
  })
  const { data, ids } = useListContext()
  const classes = useStyles()
  const selectedStatus = useSelector(
    (state: AppState) => state.manualServing.selectedStatus,
  )
  const [update] = useUpdate()
  const refresh = useRefresh()
  const notify = useNotify()
  const updateTargetOrderStatus = async (
    orderId: Identifier,
    nextStatus: any,
  ) => {
    // if (process.env.NODE_ENV === 'development') {
    //   updateData.status = nextStatus
    //   updateData.id = Number(orderId)
    //   update(
    //     'manual-servings',
    //     orderId,
    //     updateData,
    //     {},
    //     {
    //       onSuccess: () => {
    //         console.log('onSuccess')
    //       },
    //       onFailure: () => {
    //         notify('更新に失敗しました', 'error')
    //       },
    //     },
    //   )
    // } else {
    let tempStatus = ''
    if (nextStatus === 'accepted') {
      tempStatus = 'accept'
    } else if (nextStatus === 'placed') {
      tempStatus = 'place'
    }
    update(
      `manual-servings|${tempStatus}`,
      orderId,
      {},
      {},
      {
        onSuccess: () => {
          refresh()
          notify('更新に成功しました', 'success')
        },
        onFailure: () => {
          notify('更新に失敗しました', 'error')
        },
      },
    )
  }
  return (
    <Box className={classes.menu}>
      <div className={classes.navSection}>
        <FilterButton status="requested" label="未調理" />
        <FilterButton status="accepted" label="調理中" />
        <FilterButton status="placed" label="調理済" />
      </div>
      {data &&
        ids &&
        ids.map((id) => (
          <Card key={id} className={classes.record}>
            <Box className={classes.cardHeader}>
              <Typography color="textSecondary" className={classes.requestedAt}>
                {data[id].requestedAt}
              </Typography>
              <Typography className={classes.orderId}>
                オーダーID: {data[id].id}
              </Typography>
            </Box>
            {/* react-adminのSelectInputだとSaveボタンが表示されたりと面倒だったのでSelectを使用 */}
            <Select
              id="select"
              value={selectedStatus}
              onChange={(el) => {
                updateTargetOrderStatus(data[id].id, el.target.value)
              }}
              variant="outlined"
              className={classes.select}
              // classes={{ root: classes.selectRoot }}  // TODO 青い枠線を変更する
            >
              <MenuItem value={'requested'} disabled={true}>
                未調理
              </MenuItem>
              <MenuItem
                value={'accepted'}
                disabled={selectedStatus !== 'requested'}>
                調理中
              </MenuItem>
              <MenuItem
                value={'placed'}
                disabled={selectedStatus !== 'accepted'}>
                調理済み
              </MenuItem>
            </Select>
            {data[id].lineItems.map(
              ({
                item,
                options,
                count,
              }: {
                item: LineItem
                options: Option[]
                count: number
              }) => (
                <Box className={classes.orderItem} key={item.id}>
                  <img
                    src={item.imageURL}
                    className={classes.itemImage}
                    alt="画像"
                  />
                  <Box className={classes.lineItemTextSection}>
                    <Box display="flex">
                      <Typography
                        color="textPrimary"
                        className={classes.itemName}>
                        {item.name}
                      </Typography>
                      <Typography color="textPrimary" className={classes.count}>
                        &nbsp;&nbsp;&nbsp;×{count}
                      </Typography>
                    </Box>
                    {options.length > 0 && (
                      <Box>
                        <Typography
                          color="textSecondary"
                          className={classes.customItem}>
                          カスタマイズ項目
                        </Typography>
                        <Box display="flex">
                          {options.map(({ label }) => (
                            <Box key={label} className={classes.optionItem}>
                              <Typography
                                color="primary"
                                className={classes.optionLabel}>
                                {label}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              ),
            )}
            <Box display="flex" alignItems="center">
              <Typography color="textSecondary">受け取りロッカー：</Typography>
              <Typography color="textPrimary" className={classes.lockerNumber}>
                {data[id].lockerBox.number}
              </Typography>
            </Box>
          </Card>
        ))}
    </Box>
  )
}

const ManualServingList = (props: ListProps): JSX.Element => {
  const selectedStatus = useSelector(
    (state: AppState) => state.manualServing.selectedStatus,
  )
  return (
    <ListBase {...props} filterDefaultValues={{ status: selectedStatus }}>
      <ManualServingCard />
    </ListBase>
  )
}

const useStyles = makeStyles(({ palette }) => ({
  menu: {
    flex: 1,
    backgroundColor: palette.menuBackground,
    padding: '16px 24px',
  },
  navSection: {
    paddingBottom: '16px',
    paddingRight: '24px',
  },
  record: {
    position: 'relative',
    padding: '19.5px 16px',
    marginBottom: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cardHeader: {
    paddingBottom: 19.5,
  },
  requestedAt: {
    paddingBottom: 4,
    fontSize: 12,
  },
  orderId: {
    fontSize: 14,
    lineHeight: '19.5px',
    fontWeight: 'bold',
  },
  select: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  orderItem: {
    display: 'flex',
    borderBottom: `1px solid ${palette.divider}`,
    marginBottom: 16,
    marginRight: 16,
    paddingBottom: 16,
  },
  itemImage: {
    width: 80,
    height: 80,
  },
  itemName: {
    paddingBottom: 8,
    fontWeight: 700,
  },
  count: {
    fontWeight: 700,
  },
  customItem: {
    paddingBottom: 4,
    fontSize: 12,
  },
  optionItem: {
    height: 18,
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
    padding: '4px 0px',
    borderRadius: 60,
    backgroundColor: palette.primary.light,
  },
  optionLabel: {
    fontSize: 12,
    fontWeight: 700,
  },
  lineItemTextSection: {
    paddingLeft: 16,
  },
  lockerNumber: {
    fontSize: 20,
    fontWeight: 700,
  },
}))

export default ManualServingList

import React from 'react'
import { ListBase, ListProps, useListContext } from 'react-admin'
import { Card, Box, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const StationList = (props: ListProps): JSX.Element => (
  <ListBase {...props}>
    <StationCard />
  </ListBase>
)

const StationCard = (): JSX.Element => {
  const { data, ids } = useListContext()
  const classes = useStyles()
  return (
    <Box className={classes.menu}>
      <Card className={classes.stationsSection}>
        {ids.length > 0 &&
          ids.map((id) => (
            <Box key={id} className={classes.stationRecord}>
              <Typography className={classes.stationName}>
                {data[id].name}
              </Typography>
              <Button
                variant="contained"
                component={Link}
                to={`/stations/${id}/show`}
                className={classes.detailButton}
                color="primary">
                詳細
              </Button>
            </Box>
          ))}
      </Card>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }) => ({
  menu: {
    flex: 1,
    backgroundColor: palette.menuBackground,
    padding: 24,
  },
  stationsSection: {
    padding: '6px 0 24px',
  },
  stationRecord: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 0 ',
    margin: '0 24px',
    borderBottom: `1px solid ${palette.divider}`,
  },
  stationName: {
    fontWeight: 700,
  },
  detailButton: {
    height: 44,
    width: 162,
    marginLeft: 'auto',
    marginRight: 24,
    color: palette.common.white,
  },
}))

export default StationList

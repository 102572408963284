import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import * as firebase from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDQpZd-Kp945AMjtKk2DcRFOu8YX13E-M0',
  authDomain: 'bluebottle-coffee-japan.firebaseapp.com',
  projectId: 'bluebottle-coffee-japan',
  storageBucket: 'bluebottle-coffee-japan.appspot.com',
  messagingSenderId: '524049824228',
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={'omo-cloud-bluebottle-stg.jp.auth0.com'} // bluebottle-stg
      clientId={'xV9eSIvgWtzZWI1gFaVADJxqKvKqC4Dq'} // bluebottle-stg
      redirectUri={`${window.location.origin}/callback`}>
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React from 'react'
import {
  ShowProps,
  useShowController,
  useUpdate,
  useRefresh,
  Identifier,
  TabbedShowLayout,
  Tab,
  useNotify,
} from 'react-admin'
import { Box, Typography, Select, MenuItem, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Menu } from 'types'
// import updateItemData from 'mock/updateItem.json'
import LockerBoxTabList from './LockerBoxTabList'

export const StationShow = (props: ShowProps): JSX.Element => {
  const [update] = useUpdate()
  const refresh = useRefresh()
  const notify = useNotify()
  const { record, loaded } = useShowController(props)
  function toBoolean(data: string) {
    return data.toLowerCase() === 'true'
  }
  const updateTargetItemStatus = (orderId: Identifier, nextStatus: any) => {
    // アイテムのステータスを変更する
    const targetId = Number(orderId)
    // if (process.env.NODE_ENV === 'development') {
    //   updateItemData.id = targetId
    //   updateItemData.isAvailable = nextStatus
    //   update(`stations/${targetId}/show`, targetId, updateItemData)
    // } else {
    update(
      'stations|update-menus',
      record?.id,
      { menus: [{ itemID: targetId, isAvailable: toBoolean(nextStatus) }] },
      {},
      {
        onSuccess: () => {
          refresh()
          notify('更新に成功しました', 'success')
        },
        onFailure: (error: Error) => {
          console.log('error', error)
          notify('更新に失敗しました', 'error')
        },
      },
    )
    // }
  }
  const classes = useStyles()
  if (!loaded || !record || record.length === 0) return <></>
  return (
    <Box className={classes.menu}>
      <Box className={classes.contentCard} mb="24px">
        <Box className={classes.contentCardHeader}>
          <Typography className={classes.contentTitle}>店舗情報</Typography>
        </Box>
        <Divider />
        <Box className={classes.contentCardBody}>
          {/* <img TODO: 画像変更機能など実装時に再着手
            src="https://placehold.jp/80x80.png"
            className={classes.stationImage}
            alt="画像"
          /> */}
          <Box>
            <Typography color="textPrimary" className={classes.stationName}>
              {record.name}
            </Typography>
            <Typography color="textSecondary">{record.address}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.contentCard}>
        <Box className={classes.contentCardHeader}>
          <Typography color="textPrimary" className={classes.contentTitle}>
            詳細
          </Typography>
          {/* <Box className={classes.contentHeaderRowBox}>
            <Typography
              color="textSecondary"
              className={classes.contentHeaderRowTitle}>
              ステータス
            </Typography>
          </Box> */}
        </Box>
        <Divider />
        <TabbedShowLayout>
          <Tab label="アイテム">
            {record.menus &&
              record.menus.map((menu: Menu) => (
                <Box key={menu.item.id}>
                  <Box className={classes.contentCardBody}>
                    <img
                      src={record.imageU}
                      className={classes.itemImage}
                      alt="画像"
                    />
                    <Box className={classes.contentCardLabelBox}>
                      <Typography
                        color="textPrimary"
                        className={classes.itemName}>
                        ID:{menu.item.id} {menu.item.name}
                      </Typography>
                      <Typography color="textSecondary">
                        {menu.item.description}
                      </Typography>
                    </Box>
                    <Select
                      id="select"
                      value={menu.isAvailable}
                      onChange={(el) => {
                        updateTargetItemStatus(menu.item.id, el.target.value)
                      }}
                      variant="outlined"
                      className={classes.select}>
                      <MenuItem value={'false'} disabled={!menu.isAvailable}>
                        売り切れ
                      </MenuItem>
                      <MenuItem value={'true'} disabled={menu.isAvailable}>
                        購入可能
                      </MenuItem>
                    </Select>
                  </Box>
                  <Divider variant="middle" />
                </Box>
              ))}
          </Tab>
          <Tab label="ロッカー">
            <LockerBoxTabList
              stationId={Number(record.id)}
              lockerBoxes={record.lockerBoxes}
            />
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }) => ({
  menu: {
    flex: 1,
    backgroundColor: palette.menuBackground,
    padding: 24,
  },
  contentCard: {
    backgroundColor: palette.common.white,
  },
  contentCardHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
  },
  contentTitle: {
    fontWeight: 700,
    fontSize: 18,
  },
  contentCardBody: {
    position: 'relative',
    display: 'flex',
    padding: '16px 24px',
  },
  stationImage: {
    width: 120,
    height: 120,
  },
  contentCardLabelBox: {
    paddingLeft: 16,
  },
  stationName: {
    paddingBottom: 8,
    fontWeight: 700,
    fontSize: 20,
  },
  itemCard: {
    backgroundColor: palette.common.white,
  },
  contentHeaderRowBox: {
    width: 172,
    display: 'flex',
    marginLeft: 'auto',
  },
  contentHeaderRowTitle: {
    fontSize: 14,
  },
  itemImage: {
    width: 80,
    height: 80,
  },
  itemName: {
    paddingBottom: 8,
    fontSize: 18,
    fontWeight: 700,
  },
  select: {
    position: 'absolute',
    top: 16,
    right: 24,
    width: 172,
  },
}))

export default StationShow

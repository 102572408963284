import * as React from 'react'
import { AppBar, AppBarProps } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

const MyAppBar = (props: AppBarProps): JSX.Element => {
  const classes = useStyles()
  return (
    <AppBar
      {...props}
      className={classes.appBar}
      color="transparent"
      elevation={0}>
      <Box className={classes.title}>
        <Typography className={classes.dashboard}>Dashboard</Typography>
        <Typography className={classes.poweredByRootC}>
          powered by rootC
        </Typography>
      </Box>
    </AppBar>
  )
}

const useStyles = makeStyles(({ palette }) => ({
  appBar: {
    backgroundColor: palette.common.white,
  },
  title: {
    flex: 1,
  },
  dashboard: {
    fontWeight: 700,
    color: '#333333',
  },
  poweredByRootC: {
    fontSize: 10,
    color: '#333333',
  },
}))

export default MyAppBar

import React, { useState } from 'react'
import { useUpdate, useRefresh, useNotify } from 'react-admin'
import { Box, Typography, Button, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Check } from 'react-feather'
// import updateLockerData from 'mock/updateLocker.json'
import { LockerBoxes } from 'types'
import dayjs from 'dayjs'

interface Props {
  stationId: number
  lockerBoxes: LockerBoxes[]
}

const LockerBoxTabList = ({ stationId, lockerBoxes }: Props): JSX.Element => {
  const [clearModalVisible, setClearModalVisible] = useState(false)
  const [targetLockerId, updateTargetLockerId] = useState(0)
  const classes = useStyles()
  const notify = useNotify()
  const [update] = useUpdate()
  const refresh = useRefresh()
  const handleConfirmClearLocker = () => {
    setClearModalVisible(false)
    // if (process.env.NODE_ENV === 'development') {
    //   updateLockerData.id = targetLockerId
    //   updateLockerData.assigned = false
    //   update('lockers', targetLockerId, updateLockerData)
    // } else {
    update(
      `stations|check-manual-serving-received`,
      stationId,
      { lockerBoxID: targetLockerId },
      {},
      {
        onSuccess: () => {
          refresh()
          notify('更新に成功しました', 'success')
        },
        onFailure: () => {
          notify('更新に失敗しました', 'error')
        },
      },
    )
  }
  const handleClearingLocker = (id: number) => {
    // ロッカークリアボタン押下
    updateTargetLockerId(id)
    setClearModalVisible(true)
  }
  return (
    <Box className={classes.menu}>
      {lockerBoxes &&
        lockerBoxes.map((lockerBox: any) => (
          <Box key={lockerBox.id}>
            <Box className={classes.lockerRecord}>
              <Typography color="textPrimary" className={classes.lockerName}>
                ロッカー{lockerBox.number}
              </Typography>
              <Box
                className={`${classes.assignedBox} ${
                  lockerBox.manualServing
                    ? classes.assignedYesBox
                    : classes.assignedNoBox
                }`}>
                <Typography
                  color={lockerBox.manualServing ? 'primary' : 'textSecondary'}
                  className={classes.assigned}>
                  {lockerBox.manualServing ? '割当済' : '未使用'}
                </Typography>
              </Box>
              {lockerBox.manualServing && (
                <>
                  <Typography
                    color="textSecondary"
                    className={classes.orderedTime}>
                    {' '}
                    オーダー日時：
                    {dayjs(lockerBox.manualServing.requestedAt).format('HH:mm')}
                  </Typography>
                  {lockerBox.manualServing.status === 'placed' && (
                    <Button
                      onClick={() => handleClearingLocker(Number(lockerBox.id))}
                      className={classes.clearLockerButton}
                      color="primary"
                      variant="contained">
                      <Check />
                      &nbsp;ロッカーをクリア
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Box>
        ))}
      <Modal
        open={clearModalVisible}
        onClose={() => setClearModalVisible(false)}>
        <Box className={classes.modalCard}>
          <Typography color="textPrimary" className={classes.clearLocker}>
            ロッカーをクリア
          </Typography>
          <Typography color="textPrimary" className={classes.description1}>
            お客様が商品を取り出したことを確認してください
          </Typography>
          <Typography color="textPrimary" className={classes.description2}>
            ※クリアすると新規オーダーの受付が可能になります
          </Typography>
          <Box className={classes.navSection}>
            <Button
              variant="outlined"
              className={classes.baseButton}
              onClick={() => setClearModalVisible(false)}>
              キャンセル
            </Button>
            <Button
              className={`${classes.baseButton} ${classes.clearButton}`}
              onClick={() => handleConfirmClearLocker()}
              color="primary"
              variant="contained">
              クリア
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

const useStyles = makeStyles(({ palette }) => ({
  menu: {
    flex: 1,
    paddingBottom: 24,
  },
  lockerRecord: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 0',
    margin: '0 24px',
    borderBottom: `1px solid ${palette.divider}`,
  },
  lockerName: {
    fontSize: 18,
    fontWeight: 700,
  },
  assignedBox: {
    height: 32,
    width: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
    padding: '4px 8px 4px 8px',
    borderRadius: 60,
  },
  assignedYesBox: {
    backgroundColor: palette.primary.light,
  },
  assignedNoBox: {
    backgroundColor: palette.primary.contrastText,
  },
  assigned: {
    fontWeight: 700,
  },
  orderedTime: {
    paddingLeft: 32,
    fontSize: 14,
    fontWeight: 700,
  },
  clearLockerButton: {
    height: 44,
    width: 192,
    marginLeft: 'auto',
    marginRight: 24,
    color: palette.common.white,
  },
  modalCard: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 462,
    padding: '24px 0',
    backgroundColor: palette.common.white,
  },
  clearLocker: {
    paddingLeft: 25,
    paddingBottom: 24,
    fontWeight: 700,
    fontSize: 20,
    // lineHeight: '30px', なくても変わらない。指摘されたとき用に一応残しておく。
  },
  description1: {
    padding: '0 21px 8px',
  },
  description2: {
    padding: '0 21px 24px',
    fontSize: 14,
  },
  navSection: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '0 28px',
  },
  baseButton: {
    width: 146,
  },
  clearButton: {
    marginLeft: 8,
    color: palette.common.white,
  },
}))

export default LockerBoxTabList
